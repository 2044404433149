import React from "react"

// styles
import { css } from "@emotion/core"

// component
import { Drawer as Component } from "antd"

// assets
import { ReactComponent as Close } from "assets/icons/icon-close.svg"

const styling = (theme) => css`
  label: component-drawer--styling;

  .ant-drawer-body {
    padding: 0;
  }

  .ant-drawer-body > header {
    padding: 10px 20px;
  }

  .content {
    padding: 20px 20px;
  }

  header.header-lvl-1 {
    background: #e3e7f1;
  }
  header.header-lvl-2 {
    background: #ebeff7;
  }
  header.header-lvl-3 {
    background: #f7f9fc;
  }

  .close-btn {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
    opacity: 0.8;
    transition: opacity 0.3s;

    &:hover {
      opacity: 1;
    }
  }

  .component-drawer-mask-info {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    color: white;
    bottom: 0;

    cursor: default;

    padding: 0 50px;
    text-align: center;

    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: #f7f9fc;

    transition: background 0.3s;
  }
`

const Drawer = ({
  header,
  subHeader,
  subSubHeader,
  children,
  width,
  maskText,
  onClose,
  ...rest
}) => {
  return (
    <Component
      css={styling}
      placement="right"
      width={width}
      className="component-drawer"
      closable={false}
      maskStyle={{ background: "rgba(18, 36, 59, 0.85)" }}
      headerStyle={{ display: "none" }}
      {...rest}
    >
      <div
        className="component-drawer-mask-info"
        style={{ right: width }}
        onClick={onClose}
      >
        {maskText}
      </div>

      <header className="header-lvl-1">{header}</header>
      <header className="header-lvl-2">{subHeader}</header>
      <header className="header-lvl-3">{subSubHeader}</header>

      <div className="content">{children}</div>

      <Close className="close-btn" onClick={onClose} />
    </Component>
  )
}

export default Drawer
